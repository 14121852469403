import React from "react"
import styled from "styled-components"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import * as Styles from "./styles"
import { Typography } from "@material-ui/core"
import _ from "lodash"
import moment from "moment"

function renderTrackerWrapper(props) {
  const { trackerList, width, handleAccordionChange } = props

  return (
    <div>
      {trackerList.map((stepObject, idx) =>
        renderOneStepOfTracker({
          stepObject,
          idx,
          lastElement: trackerList.length === idx + 1,
          handleAccordionChange,
          width,
        })
      )}
    </div>
  )
}

function renderOneStepOfTracker(props) {
  const { stepObject, idx, lastElement, handleAccordionChange, width } = props
  return (
    <Styles.Wrapper>
      <Styles.StepTrackWrapper idx={idx}>
        {renderStepTrackLine({
          status: stepObject.currentPoseStatus,
          lastElement,
        })}
      </Styles.StepTrackWrapper>
      <div style={{ width: "100%" }}>
        {renderAccordianPart({
          heading: stepObject.updated_status,
          tag: stepObject.status,
          status: stepObject.updated_status,
          isOpen: stepObject.isOpen,
          dateTimeStamp: moment(_.get(stepObject, "utc_datetime", "")).isValid()
            ? moment(_.get(stepObject, "utc_datetime", "")).format(
                "DD-MMM-YYYY hh:mm:ss A"
              )
            : "-",
          content: renderDynamicTemplateContent({
            stepObject,
            templateType: 1,
          }),
          idx,
          handleAccordionChange,
          width,
        })}
      </div>
    </Styles.Wrapper>
  )
}

function renderStepTrackLine(props) {
  const { status, lastElement } = props

  return (
    <>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ height: "100%" }}>
          <Styles.StepBall status={status} />
          {!lastElement && <Styles.StepLine status={status} />}
        </div>
        <div>
          <Styles.MidLine />
        </div>
      </div>
    </>
  )
}

function renderAccordianPart(props) {
  const {
    idx,
    heading,
    status,
    isOpen,
    dateTimeStamp,
    content,
    handleAccordionChange,
    width,
  } = props

  return (
    <Styles.AccordionTag
      expanded={isOpen}
      className={"AccordianTag here"}
      onChange={(event, isExpanded) => {
        handleAccordionChange(idx, isExpanded)
      }}
      width={width}
    >
      <Styles.AccordionSummaryTag
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        status={status}
      >
        <Styles.SummaryHeaderWrapper>
          <Styles.SummaryHeader status={status}>
            <Styles.Heading>{_.upperCase(heading)}</Styles.Heading>
          </Styles.SummaryHeader>
          <Styles.SummaryDateTime status={status}>
            <Typography variant="h5">{dateTimeStamp}</Typography>
          </Styles.SummaryDateTime>
        </Styles.SummaryHeaderWrapper>
      </Styles.AccordionSummaryTag>

      <Styles.AccordionDetailsTag>
        {content && <>{content}</>}
      </Styles.AccordionDetailsTag>
    </Styles.AccordionTag>
  )
}

function renderDynamicTemplateContent(innerProps) {
  const { stepObject = {}, templateType = 1 } = innerProps || {}
  const cfTemplateType = {
    1: renderContentOne,
  }
  const DynamicTemplate = cfTemplateType[templateType]
  return <DynamicTemplate {...stepObject} />
}

function renderContentOne(props) {
  const { message, updated_user_name, utc_datetime } = props
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div style={{ minHeight: '39px' }}>
        {message
          ? <Typography variant="subtitle1">{message}</Typography>
          : (
            <Typography variant="subtitle1"
              style={{
                color: 'gray',
                textAlign: 'center'
              }}
            >
              No Content
            </Typography>
          )
        }
      </div>
      <Styles.DottedHrLine />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {updated_user_name && (
          <Typography
            variant="subtitle2"
            style={{
              color: "#808081",
            }}
          >
            Updated By: {updated_user_name}
          </Typography>
        )}
        {utc_datetime && (
          <Typography
            variant="subtitle2"
            style={{
              fontStyle: "italic",
              color: "#808081",
            }}
          >
            {utc_datetime}
          </Typography>
        )}
      </div>
    </div>
  )
}

export { renderTrackerWrapper }
