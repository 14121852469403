import {
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Switch,
} from "@material-ui/core"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import { Autocomplete } from "@material-ui/lab"
import HelpIcon from "@material-ui/icons/Help"
import * as Adm from "@adm"
import * as Yup from "yup"
import React, { useState } from "react"
import { Formik } from "formik"
import _, { values } from "lodash"
import * as constantsAPI from "@constants"
import * as ServiceAPI from "@services"
import { CloseRounded } from "@material-ui/icons"
import styled from "styled-components"
import { useStyles, ProDialog, StyledAutocomplete } from "./style"
import { InfoIconKit } from "@iconsGallery"
import { KeyboardDatePicker } from "@material-ui/pickers"
import moment from "moment"
import * as Utils from "@utils"

const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

export const renderProductCard = (innerProductProps) => {
  const {
    product = {},
    onRemove = () => "",
    editImageURL = "",
    editProductName = "",
    editItemTitle = "",
    editBarcodeValue = "",
    editStockCode = "",
    editModel = false,
  } = innerProductProps
  const {
    _id = "",
    barcodeValue = editBarcodeValue || "",
    stockCode = editStockCode || "",
    item_id = editItemTitle || "",
    title = editProductName || "",
    imageUrl = editImageURL || "/static/img/noproducts.png",
  } = product
  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: 20,
      }}
    >
      <Typography
        variant="h4"
        style={{ fontSize: 15 }}
      >{`Added Product:`}</Typography>
      <Grid
        style={{
          marginTop: 4,
          width: "100%",
          minHeight: 110,
          background: "#F2F2F2",
          borderRadius: 10,
          border: "1px solid black",
          padding: 15,
          position: "relative",
        }}
      >
        {title && title.length > 0 ? (
          <>
            {!editModel && (
              <div
                style={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  cursor: "pointer",
                }}
                onClick={onRemove}
              >
                {`X`}
              </div>
            )}
            <Grid
              style={{
                paddingLeft: "0px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                src={imageUrl}
                alt={title}
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: 80,
                  maxHeight: 80,
                  minWidth: 80,
                  minHeight: 80,
                }}
              />
              <Grid
                style={{
                  marginLeft: 20,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  width: "fit-content",
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <Grid
                  style={{
                    wordWrap: "break-word",
                    display: "inline-block",
                    fontWeight: "bold",
                    margin: 0,
                    fontSize: 15,
                    width: "180px",
                  }}
                >{`${title}`}</Grid>
                {window["isEnableItemId"] === true && (
                  <Grid
                    style={{
                      wordWrap: "break-word",
                      margin: 0,
                      display: "inline-block",
                      fontSize: 13,
                      width: "180px",
                    }}
                  >{`Item Id: ${item_id}`}</Grid>
                )}
                <Grid
                  style={{
                    wordWrap: "break-word",
                    margin: 0,
                    display: "inline-block",
                    fontSize: 13,
                    width: "180px",
                  }}
                >{`Bar Code: ${barcodeValue}`}</Grid>
                <Grid
                  style={{
                    wordWrap: "break-word",
                    margin: 0,
                    display: "inline-block",
                    fontSize: 13,
                    width: "180px",
                  }}
                >{`SKU: ${stockCode}`}</Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid> {`Product Not Added`}</Grid>
        )}
      </Grid>
    </Grid>
  )
}

export const renderStar = () => <span style={{ color: "red" }}>*</span>

export const renderFieldTitle = (props) => {
  const {
    edit = false,
    title = "xxx",
    renderChild = () => "",
    renderAdditionalField = () => "",
    mandatory = true,
  } = props
  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: 36,
      }}
    >
      <Typography variant="h5">
        {title}
        {edit && mandatory && renderStar()}
        {renderChild()}
      </Typography>
      {renderAdditionalField()}
    </Grid>
  )
}

export const renderModalHeader = (props) => {
  const { setIsOpen = () => "" } = props
  return (
    <React.Fragment>
      <DialogTitle id="scroll-dialog-title">
        <Grid container item xs={12} direction="row">
          <Grid item xs={10}>
            <Typography
              variant="h4"
              style={{ fontSize: 20 }}
            >{`Associate Products`}</Typography>
          </Grid>
          <Grid item xs={2} container justify="flex-end">
            <Tooltip title="Close" placement="top">
              <IconButton size="small" onClick={() => setIsOpen(false)}>
                <CloseRounded />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
    </React.Fragment>
  )
}

export const renderFieldInventoryOnHand = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Inventory On Hand",
        edit,
        renderChild: () => {
          return (
            <>
              {editModel && (
                <Tooltip
                  title="Update the Inventory on hand solely through the Adjustments module to ensure the accurate tracking of Inventory"
                  arrow
                  placement="top"
                >
                  <HelpIcon style={{ margin: "0 0 -5px 5px" }} />
                </Tooltip>
              )}
            </>
          )
        },
      })}
      <Grid>
        <TextField
          type="text"
          name="inventoryOnHand"
          className={classes.textInput}
          placeholder="Enter Available on Hand"
          value={values?.inventoryOnHand}
          error={Boolean(touched.inventoryOnHand && errors.inventoryOnHand)}
          helperText={touched.inventoryOnHand && errors.inventoryOnHand}
          onBlur={handleBlur}
          onKeyDown={(e) => handleWhiteSpace(e)}
          onKeyPress={(e) => handleKeyPress(e, "text")}
          onChange={(e) => {
            handleChange(e)
            onGlobalUpdate()
          }}
          variant="outlined"
          size="small"
          disabled={editModel}
          style={editModel ? { backgroundColor: "#f6f6f6" } : {}}
        />
      </Grid>
    </Grid>
  )
}

export const renderFieldListPrice = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    getPrepopuplate,
  } = props

  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({ title: "List Price", edit })}
      <Grid>
        <TextField
          type="text"
          className={classes.textInput}
          name="listPrice"
          placeholder="Enter List Price"
          value={values.listPrice}
          error={Boolean(touched.listPrice && errors.listPrice)}
          helperText={touched.listPrice && errors.listPrice}
          onBlur={handleBlur}
          onKeyDown={(e) => handleWhiteSpace(e)}
          onKeyPress={(e) => handleKeyPress(e, "text")}
          onChange={(e) => {
            handleChange(e)
            getPrepopuplate(e)
            onGlobalUpdate()
          }}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {Utils?.JSUtils?.getLSCurrencyCode().currency_symbol}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
}

export const renderFieldOfferPrice = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    getTaxPrice,
    isStorage = false,
    getIsInclude,
    setIsInclude = () => "",
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Offer Price",
        edit,
        renderAdditionalField: () => {
          return isStorage ? (
            <></>
          ) : (
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Switch
                sx={{
                  "&.MuiSwitch-root .MuiSwitch-switchBase": {
                    color: "red",
                  },

                  "&.MuiSwitch-root .Mui-checked": {
                    color: "green",
                  },
                }}
                key={getIsInclude}
                defaultChecked={getIsInclude}
                onClick={(e) => {
                  setIsInclude(e.target.checked)
                }}
                name="checkedValue"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <Grid>{`Tax ${getIsInclude ? "Include" : "Exclude"} (%)`}</Grid>
            </Grid>
          )
        },
      })}
      <Grid>
        <TextField
          type="text"
          name="offerPrice"
          className={classes.textInput}
          placeholder="Enter Offer Price"
          value={values.offerPrice}
          error={Boolean(touched.offerPrice && errors.offerPrice)}
          helperText={touched.offerPrice && errors.offerPrice}
          onBlur={handleBlur}
          onKeyDown={(e) => handleWhiteSpace(e)}
          onKeyPress={(e) => handleKeyPress(e, "text")}
          onChange={(e) => {
            handleChange(e)
            getTaxPrice(e, "offerPrice")
            onGlobalUpdate()
          }}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {Utils?.JSUtils?.getLSCurrencyCode().currency_symbol}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
}

export const renderFieldMinimumThreshold = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    isStorage = false,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: isStorage ? "Reorder Threshold" : "Minimum Threshold",
        edit,
      })}
      <Grid style={{ float: "right", marginTop: "-25px" }}>
        <Tooltip
          title={`Set the ${isStorage ? `Reorder threshold` : `Minimum Threshold`
            } limit to trigger restock notification`}
          placement="left"
        >
          <IconButton style={{ padding: "0" }}>
            <InfoIconKit fill="#af2b99" />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid>
        <TextField
          type="text"
          className={classes.textInput}
          name="minimumThreshold"
          placeholder={`Enter ${isStorage ? `Reorder Threshold` : `Minimum Threshold`
            } `}
          value={values.minimumThreshold}
          error={Boolean(touched.minimumThreshold && errors.minimumThreshold)}
          helperText={touched.minimumThreshold && errors.minimumThreshold}
          onBlur={handleBlur}
          onKeyDown={(e) => handleWhiteSpace(e)}
          onKeyPress={(e) => handleKeyPress(e, "text")}
          onChange={(e) => {
            handleChange(e)
            onGlobalUpdate()
          }}
          variant="outlined"
          size="small"
        />
      </Grid>
    </Grid>
  )
}

export const renderFieldTaxRate = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    isCheckTax = false,
    getTaxPrice,
    getPrepopuplate,
  } = props
  return (
    <React.Fragment>
      {isCheckTax && (
        <Grid className={`cls-field-form-kit`}>
          {renderFieldTitle({
            title: "Tax Rate (%)",
            edit,
          })}

          <Grid>
            <TextField
              type="text"
              className={classes.textInput}
              name="taxRate"
              placeholder="Enter Tax Rate"
              value={values.taxRate}
              error={Boolean(touched.taxRate && errors.taxRate)}
              helperText={touched.taxRate && errors.taxRate}
              onBlur={handleBlur}
              onKeyDown={(e) => handleWhiteSpace(e)}
              onKeyPress={(e) => handleKeyPress(e, "text")}
              onChange={(e) => {
                handleChange(e)
                getTaxPrice(e, "taxRate")
                onGlobalUpdate()
              }}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export const renderFieldTaxValue = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    isCheckTax,
  } = props
  return (
    <React.Fragment>
      {isCheckTax && (
        <Grid className={`cls-field-form-kit`}>
          {renderFieldTitle({
            title: "Tax Value",
            edit,
          })}

          <Grid>
            <TextField
              type="text"
              name="taxValue"
              className={classes.disabledTextInput}
              disabled
              value={values?.taxValue}
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export const renderFieldItemId = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    productListObj,
    handleProductList,
    productOpen,
    setProductOpen,
    handleDialogClick,
    handleScroll,
  } = props
  const renderOptions = (option, props) => {
    let itemId = props
    const [_productItemKit] = productListObj?.productRawList.filter((itm) =>
      itm.item_id.includes(itemId)
    )
    const {
      title: productName = "",
      imageUrl = "",
      barcodeValue = "",
      stockCode = "",
    } = _productItemKit
    return (
      <ListItem
        style={{
          paddingLeft: "0px",
        }}
      >
        <img
          src={imageUrl}
          alt={productName}
          style={{
            width: "100%",
            height: "auto",
            marginRight: 5,
            maxWidth: 70,
            maxHeight: 70,
            minWidth: 70,
            minHeight: 70,
          }}
        />
        <ListItem
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            width: "fit-content",
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <ListItemText
            primary={`Product Name: ${productName}`}
            primaryTypographyProps={{
              style: {
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "inline-block",
                whiteSpace: "nowrap",
                fontWeight: "bold",
                margin: 0,
              },
            }}
            style={{ margin: 0 }}
          />
          {window["isEnableItemId"] === true && (
            <ListItemText
              style={{ margin: 0 }}
              primary={`Item ID: ${itemId}`}
              primaryTypographyProps={{
                style: {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  margin: 0,
                },
              }}
            />
          )}
          <ListItemText
            style={{ margin: 0 }}
            primary={`BarCode: ${barcodeValue}`}
            primaryTypographyProps={{
              style: {
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "inline-block",
                whiteSpace: "nowrap",
                margin: 0,
              },
            }}
          />
          <ListItemText
            style={{ margin: 0 }}
            primary={`SKU : ${stockCode}`}
            primaryTypographyProps={{
              style: {
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "inline-block",
                whiteSpace: "nowrap",
                margin: 0,
              },
            }}
          />
        </ListItem>
      </ListItem>
    )
  }
  const filterOptions = createFilterOptions({
    stringify: (name) => {
      let itemId = name
      const [_productItemKit] = productListObj?.productRawList.filter((itm) =>
        itm.item_id.includes(itemId)
      )
      const { title: productName = "", barcodeValue = "" } = _productItemKit
      return `${productName} ${itemId} ${barcodeValue}`
    },
  })


  return (
    <>
      {!editModel && (
        <Grid>
          {renderFieldTitle({
            title: "Search Products" || "Item ID",
            edit,
          })}
          <Grid style={{ float: "right", marginTop: "-25px" }}>
            <Tooltip
              title={
                window["isEnableItemId"] === true
                  ? `Search by Product Name, Item ID, SKU and barcode Value.`
                  : `Search by Product Name, SKU and barcode Value.`
              }
              placement="top"
            >
              <IconButton style={{ padding: "0" }}>
                <InfoIconKit fill="#af2b99" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid>
            <StyledAutocomplete
              className={`cls-field-form-kit`}
              classes={{ option: classes.option }}
              renderOption={(props, option) => renderOptions(option, props)}
              filterSelectedOptions={true}
              filterOptions={filterOptions}
              options={productListObj?.productDropdownData}
              onChange={(e, val) => {
                handleProductList(e, val)
              }}
              open={productOpen}
              disableClearable={true}
              value={values?.productTitle}
              disabled={editModel ? true : false}
              style={editModel ? { backgroundColor: "#f6f6f6" } : {}}
              onOpen={(e) => {
                setProductOpen(true)
                handleDialogClick(e)
              }}
              onClose={(e) => {
                setProductOpen(false)
                handleDialogClick(e)
              }}
              ListboxProps={{
                onScroll: (e) => handleScroll(e),
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    className={classes.autoCompleteHeight}
                    placeholder="Search"
                    error={Boolean(touched.productName && errors.productName)}
                    helperText={touched.productName && errors.productName}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export const renderActionButton = (props) => {
  const { resetForm, handleSubmit, editModel } = props
  const savebuttonAttrs = {
    variant: "contained",
    type: "submit",
    color: "primary",
  }

  const cancelbuttonAttrs = {
    variant: "contained",
    type: "cancel",
    color: "primary",
  }
  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: "30px",
        flexDirection: "row",
        padding: 20,
      }}
    >
      <Grid>
        <Adm.Button
          {...cancelbuttonAttrs}
          onClick={() => resetForm()}
          style={{
            backgroundColor: "#959595",
            color: "#fff",
            width: 100,
            marginRight: 10,
          }}
        >
          {`RESET`}
        </Adm.Button>
        <Adm.Button
          {...savebuttonAttrs}
          style={{
            backgroundColor: "var(--primaryColor)",
            color: "#fff",
            width: 100,
          }}
          onClick={handleSubmit}
        >
          {editModel ? "UPDATE" : "SUBMIT"}
        </Adm.Button>
      </Grid>
    </Grid>
  )
}

export const renderFieldPackageType = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    productListObj,
    handlePackageType,
    productOpen,
    setProductOpen,
    handleDialogClick,
    handleScroll,
    packageList = [],
  } = props
  let lcPackageList = packageList.map((item) => item.name)
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Package Type",
        edit,
      })}
      <Grid>
        <StyledAutocomplete
          options={lcPackageList}
          onChange={handlePackageType}
          disableClearable={true}
          value={values?.package_type}
          disabled={editModel ? true : false}
          style={editModel ? { backgroundColor: "#f6f6f6" } : {}}
          onOpen={(e) => { }}
          onClose={(e) => { }}
          ListboxProps={{
            onScroll: (e) => handleScroll(e),
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="outlined"
                className={classes.autoCompleteHeight}
                placeholder="Select Package Type"
                error={Boolean(touched.package_type && errors.package_type)}
                helperText={touched.package_type && errors.package_type}
              />
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

export const renderFieldReorderQuantity = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Reorder Quantity",
        edit,
      })}
      <Grid style={{ float: "right", marginTop: "-25px" }}>
        <Tooltip
          title={`Set the Quantity to be Ordered via Purchase Order`}
          placement="left"
        >
          <IconButton style={{ padding: "0" }}>
            <InfoIconKit fill="#af2b99" />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid>
        <TextField
          type="text"
          className={classes.textInput}
          name="reorder_quantity"
          placeholder="Enter Reorder Quantity"
          value={values?.reorder_quantity}
          error={Boolean(touched.reorder_quantity && errors.reorder_quantity)}
          helperText={touched.reorder_quantity && errors.reorder_quantity}
          onBlur={handleBlur}
          onKeyDown={(e) => handleWhiteSpace(e)}
          onKeyPress={(e) => handleKeyPress(e, "text")}
          onChange={(e) => {
            handleChange(e)
            onGlobalUpdate()
          }}
          variant="outlined"
          size="small"
        />
      </Grid>
    </Grid>
  )
}

export const renderFieldPackageReceived = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Package Received",
        edit,
      })}
      <Grid style={{ float: "right", marginTop: "-25px" }}>
        <Tooltip
          title={`Total Packages Received in the Order`}
          placement="left"
        >
          <IconButton style={{ padding: "0" }}>
            <InfoIconKit fill="#af2b99" />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid>
        <TextField
          type="text"
          className={classes.textInput}
          name="package_received"
          placeholder="Enter Package Received"
          value={values.package_received}
          error={Boolean(touched.package_received && errors.package_received)}
          helperText={touched.package_received && errors.package_received}
          onBlur={handleBlur}
          onKeyDown={(e) => handleWhiteSpace(e)}
          onKeyPress={(e) => handleKeyPress(e, "text")}
          onChange={(e) => {
            handleChange(e)
            onGlobalUpdate()
          }}
          variant="outlined"
          size="small"
        />
      </Grid>
    </Grid>
  )
}

export const renderFieldOrderQuantity = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    setFieldValue,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Order Quantity",
        edit,
      })}
      <Grid style={{ float: "right", marginTop: "-25px" }}>
        <Tooltip
          title={`Total Quantity Ordered while Purchase`}
          placement="left"
        >
          <IconButton style={{ padding: "0" }}>
            <InfoIconKit fill="#af2b99" />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid>
        <TextField
          type="text"
          className={classes.textInput}
          name="order_quantity"
          placeholder="Enter Package Received"
          value={values.order_quantity}
          error={Boolean(touched.order_quantity && errors.order_quantity)}
          helperText={touched.order_quantity && errors.order_quantity}
          onBlur={handleBlur}
          onKeyDown={(e) => handleWhiteSpace(e)}
          onKeyPress={(e) => handleKeyPress(e, "text")}
          onChange={(e) => {
            handleChange(e)
            onGlobalUpdate()
            setFieldValue("order_received", e?.target?.value)
            if (values?.unit_price !== "") {
              let invnCalc = e?.target?.value * values?.unit_price
              setFieldValue("total_price", invnCalc.toString())
            }
          }}
          variant="outlined"
          size="small"
        />
      </Grid>
    </Grid>
  )
}

export const renderFieldOrderReceived = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    setFieldValue,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Order Received",
        edit,
      })}
      <Grid style={{ float: "right", marginTop: "-25px" }}>
        <Tooltip title={`Total Quantity Received in Delivery`} placement="left">
          <IconButton style={{ padding: "0" }}>
            <InfoIconKit fill="#af2b99" />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid>
        <TextField
          type="text"
          className={classes.textInput}
          name="order_received"
          placeholder="Enter Order Received"
          value={values.order_received}
          error={Boolean(touched.order_received && errors.order_received)}
          helperText={touched.order_received && errors.order_received}
          onBlur={handleBlur}
          onKeyDown={(e) => handleWhiteSpace(e)}
          onKeyPress={(e) => handleKeyPress(e, "text")}
          onChange={(e) => {
            handleChange(e)
            onGlobalUpdate()
            if (values?.unit_price !== "") {
              let invnCalc = values?.unit_price * e?.target?.value
              setFieldValue("total_price", invnCalc.toString())
            }
          }}
          variant="outlined"
          size="small"
        />
      </Grid>
    </Grid>
  )
}

export const renderFieldUnitPrice = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
    setFieldValue,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Unit Price",
        edit,
      })}

      <Grid>
        <TextField
          type="text"
          className={classes.textInput}
          name="unit_price"
          placeholder="Enter Unit Price"
          value={values.unit_price}
          error={Boolean(touched.unit_price && errors.unit_price)}
          helperText={touched.unit_price && errors.unit_price}
          onBlur={handleBlur}
          onKeyDown={(e) => handleWhiteSpace(e)}
          onKeyPress={(e) => handleKeyPress(e, "text")}
          onChange={(e) => {
            handleChange(e)
            onGlobalUpdate()
            if (values?.order_received !== "") {
              let invnCalc = e?.target?.value * values?.order_received
              setFieldValue("total_price", invnCalc.toString())
            }
          }}
          variant="outlined"
          size="small"
        />
      </Grid>
    </Grid>
  )
}

export const renderFieldTotalPrice = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Total Price",
        edit,
      })}

      <Grid
        style={{
          background: "#F2F2F2",
        }}
      >
        <TextField
          type="text"
          className={classes.textInput}
          name="total_price"
          placeholder="Enter Total Price"
          value={
            (values.total_price = (values.order_received * values.unit_price)
              ?.toFixed(2)
              .toString())
          }
          onBlur={handleBlur}
          onKeyDown={(e) => handleWhiteSpace(e)}
          onKeyPress={(e) => handleKeyPress(e, "text")}
          onChange={(e) => {
            handleChange(e)
            onGlobalUpdate()
          }}
          variant="outlined"
          size="small"
        />
      </Grid>
    </Grid>
  )
}

export const renderFieldBatchNumber = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleChange,
    onGlobalUpdate,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Batch Number",
        edit,
      })}

      <Grid>
        <TextField
          type="text"
          className={classes.textInput}
          name="batch_number"
          placeholder="Enter Batch Number"
          value={values.batch_number}
          error={Boolean(touched.batch_number && errors.batch_number)}
          helperText={touched.batch_number && errors.batch_number}
          onBlur={handleBlur}
          onKeyDown={(e) => handleWhiteSpace(e)}
          onKeyPress={(e) => handleKeyPress(e, "text")}
          onChange={(e) => {
            handleChange(e)
            onGlobalUpdate()
          }}
          variant="outlined"
          size="small"
        />
      </Grid>
    </Grid>
  )
}
export const renderFieldExpiryDate = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleBlur,
    handleWhiteSpace,
    handleKeyPress,
    handleDate,
    handleChange,
    onGlobalUpdate,
    setFieldValue,
  } = props
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Expiry Date",
        edit,
        mandatory: false,
      })}

      <Grid>
        <KeyboardDatePicker
          clearLabel
          disableToolbar
          inputVariant="outlined"
          format="MM-dd-yyyy"
          placeholder="MM-DD-YYYY"
          margin="normal"
          id="date-picker-inline"
          style={{ width: "100%" }}
          value={values?.expiry_date || null}
          KeyboardButtonProps={{ "aria-label": "change date" }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setFieldValue("expiry_date", e)
          }}
          minDate={moment()}
          className={classes.keyboardDatePicker}
        />
      </Grid>
    </Grid>
  )
}

export const renderFieldVendor = (props) => {
  const {
    edit,
    editModel,
    classes,
    values,
    touched,
    errors,
    handleVendor,
    handleScroll,
    vendorList = [],
  } = props
  let lcVendorList = vendorList.map((item) => item.name)
  return (
    <Grid className={`cls-field-form-kit`}>
      {renderFieldTitle({
        title: "Vendor",
        edit,
      })}
      <Grid>
        <StyledAutocomplete
          options={lcVendorList}
          onChange={handleVendor}
          disableClearable={true}
          value={values?.company_id}
          onOpen={(e) => { }}
          onClose={(e) => { }}
          ListboxProps={{
            onScroll: (e) => handleScroll(e),
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="outlined"
                className={classes.autoCompleteHeight}
                placeholder="Select Vendor"
                error={Boolean(touched.company_id && errors.company_id)}
                helperText={touched.company_id && errors.company_id}
              />
            )
          }}
        />
      </Grid>
    </Grid>
  )
}
