import { Grid, Typography } from "@material-ui/core"
import * as Adm from "@adm"
import * as Yup from "yup"
import React, { useState } from "react"
import { Formik } from "formik"
import _ from "lodash"
import * as constantsAPI from "@constants"

import {
  useStyles,
  ProDialog,
  StyledMainWrapper,
  StyledOverAllMainWrapper,
  StyledSideWrapper,
  StyledSideRightWrapper,
} from "./style"
import {
  renderModalHeader,
  renderProductCard,
  renderFieldItemId,
  renderActionButton,
} from "./renderProps"
import {
  doDataInitialValues,
  doValidationSchema,
  doRenderList,
} from "./fixture"

function StoreProductMapping(props) {
  const classes = useStyles()
  const {
    isOpen,
    edit = false,
    setIsOpen,
    setProductName = () => { },
    editModel,
    copyData,
    fetchData,
    setLoading,
    locationId,
    productName,
    setProductOpen,
    setAnyChanges,
    productOpen,
    productListObj,
    packageList = [],
    handleScroll = () => "",
    editData,
    onUpdateSave = () => "",
    onUpdateSubmit = () => "",
    isStorage = false,
    viewPage = "edit",
    editImageURL = "",
    editProductName = "",
    editItemTitle = "",
    editBarcodeValue = "",
    editStockCode = "",
    vendorList = [],
    defaultIsInclude = false,
  } = props

  const [getIsInclude, setIsInclude] = useState(defaultIsInclude)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  let textError = "value must be greater than or equal to 0"

  const savebuttonAttrs = {
    variant: "contained",
    type: "submit",
    color: "primary",
  }
  const handleDialogClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  const handleKeyPress = (e, type) => {
    if (type === "number") {
      if (e.which === 32) {
        e.preventDefault()
      }
    } else {
      if (e.which === 32 && e.target.value === "") {
        e.preventDefault()
      }
    }
  }
  const handleWhiteSpace = (e) => {
    if (e.which === 32) {
      e.preventDefault()
    }
  }
  const onGlobalUpdate = () => {
    setAnyChanges(true)
  }

  const initialValues = doDataInitialValues(editData, isStorage, viewPage)

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault()
  }

  const onSave = async (values, props) => {
    onUpdateSave({
      values: { ...values, isInclude: getIsInclude },
      setAlert,
      setAlertType,
      setAlertMessage,
    })
  }

  const onSubmitHandler = async (values, { resetForm }) => {
    onUpdateSubmit({
      values: { ...values, isInclude: getIsInclude },
      setAlert,
      setAlertType,
      setAlertMessage,
    })
  }

  let isCheckTax =
    _.upperCase(localStorage.getItem("taxProvider")) === _.upperCase("manual")

  React.useEffect(() => {
    if (isStorage === false) {
      setIsInclude(defaultIsInclude)
    }
  }, [defaultIsInclude, isOpen])
  return (
    <React.Fragment>
      <ProDialog
        onClick={handleDialogClick}
        open={isOpen}
        onClose={() => {
          setProductOpen(false)
          setIsOpen(false)
        }}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <Formik
          enableReinitialize={false}
          initialValues={initialValues}
          validationSchema={Yup.object().shape(
            doValidationSchema(
              {
                isTax: isCheckTax,
              },
              isStorage,
              viewPage
            )
          )}
          onSubmit={editModel ? onSave : onSubmitHandler}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            resetForm,
            handleReset,
            onSubmitHandler,
            handleSubmit,
          }) => {
            const getTaxPrice = (e, control) => {
              var offerPrice = values?.offerPrice || 0
              var taxRate = values?.taxRate || 0
              if (control === "offerPrice") {
                offerPrice = e?.target?.value || 0
              } else {
                taxRate = e?.target?.value || 0
              }
              var taxPrice = Number(
                (parseFloat(offerPrice) * parseFloat(taxRate)) / 100
              ).toFixed(2)
              setFieldValue("taxValue", taxPrice)
            }

            const getPrepopuplate = (e) => {
              setFieldValue("offerPrice", e?.target?.value)
              var offerPrice = e?.target?.value || 0
              var taxRate = values?.taxRate || 0
              var taxPrice = Number(
                (parseFloat(offerPrice) * parseFloat(taxRate)) / 100
              ).toFixed(2)
              setFieldValue("taxValue", taxPrice)
            }

            const handleProductList = (e, val) => {
              setFieldValue("productName", val)
              const filteredProducts = productListObj?.productObjDetails.filter(prodObj => prodObj.item_id === val);
              let productTitle = filteredProducts?.[0]?.title;
              setFieldValue("productTitle", productTitle)
            }
            const handlePackageType = (e, val) => {
              setFieldValue("package_type", val)
            }
            const handleVendor = (e, val) => {
              setFieldValue("company_id", val)
            }

            const { productName } = values

            let selectedProductItem = {}
            if (
              productName &&
              productName.length > 0 &&
              productListObj?.productRawList?.length > 0
            ) {
              let itemId = productName
              const [_productItemKit] =
                productListObj?.productRawList.filter((itm) =>
                  itm.item_id.includes(itemId)
                ) || {}
              selectedProductItem = _productItemKit
            }

            const formProps = {
              getTaxPrice,
              getPrepopuplate,
              edit,
              editModel,
              classes,
              values,
              touched,
              errors,
              handleBlur,
              handleWhiteSpace,
              handleKeyPress,
              handleChange,
              onGlobalUpdate,
              isCheckTax,
              productListObj,
              handleProductList,
              handlePackageType,
              handleVendor,
              productOpen,
              setProductOpen,
              handleDialogClick,
              handleScroll,
              packageList,
              editImageURL,
              editProductName,
              editItemTitle,
              editBarcodeValue,
              editStockCode,
              setFieldValue,
              vendorList,
              isStorage,
              getIsInclude,
              setIsInclude,
            }

            let dataRender = doRenderList(isStorage, viewPage)
            return (
              <form onSubmit={handleSubmit}>
                {edit ? (
                  <>
                    {renderModalHeader({ setIsOpen })}
                    <StyledOverAllMainWrapper>
                      <StyledSideWrapper>
                        {renderFieldItemId(formProps)}
                        {renderProductCard({
                          ...formProps,
                          product: selectedProductItem,
                          onRemove: () => {
                            setFieldValue("productName", "")
                          },
                        })}
                      </StyledSideWrapper>
                      <StyledSideRightWrapper>
                        <Typography
                          variant="h3"
                          style={{ padding: 20, paddingBottom: 0 }}
                        >{`Product Information`}</Typography>
                        <StyledMainWrapper>
                          {dataRender.map((render, index) => {
                            return render(formProps)
                          })}
                        </StyledMainWrapper>
                      </StyledSideRightWrapper>
                    </StyledOverAllMainWrapper>
                    {renderActionButton({
                      parentProps: props,
                      resetForm,
                      savebuttonAttrs,
                      handleSubmit,
                      editModel,
                    })}
                  </>
                ) : null}
              </form>
            )
          }}
        </Formik>
      </ProDialog>
      {alert ? (
        <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
      ) : null}
    </React.Fragment>
  )
}

export default StoreProductMapping
export { StoreProductMapping }
