import * as Yup from "yup"
import _, { values } from "lodash"
import {
  renderFieldInventoryOnHand,
  renderFieldListPrice,
  renderFieldOfferPrice,
  renderFieldMinimumThreshold,
  renderFieldTaxRate,
  renderFieldTaxValue,
  renderFieldPackageType,
  renderFieldReorderQuantity,
  renderFieldPackageReceived,
  renderFieldOrderQuantity,
  renderFieldOrderReceived,
  renderFieldUnitPrice,
  renderFieldTotalPrice,
  renderFieldExpiryDate,
  renderFieldBatchNumber,
  renderFieldVendor,
} from "./renderProps"
import moment from "moment"
export const doDataInitialValues = (
  editData,
  isStorage = false,
  viewPage = "edit"
) => {
  let taxData = {
    taxRate: _.get(editData, "taxRate", "") || "",
    taxValue: _.get(editData, "taxValue", "") || "",
  }
  let commonData = {
    productTitle: _.get(editData, "itemTitle", "") || "",   // product Name
    productName: _.get(editData, "productName", "") || "", // product ID
    inventoryOnHand: _.toString(_.get(editData, "inventoryOnHand", "")) || "",
    minimumThreshold: _.toString(_.get(editData, "minimumThreshold", "")) || "",
    _id: _.get(editData, "_id", "") || "",
  }

  /**
   * @LOCATION_STORE_DATA
   */
  let storeData = {
    itemTitle: _.get(editData, "itemTitle", "") || "",
    listPrice: _.get(editData, "listPrice", "") || "",
    offerPrice: _.get(editData, "offerPrice", "") || "",
    ...taxData,
  }

  /**
   * @STORAGE_DATA
   */

  let storageCreate =
    viewPage === "edit"
      ? {}
      : {
        package_type: _.get(editData, "package_type", "") || "",
        package_received: _.get(editData, "package_received", "") || "",
        order_quantity: _.get(editData, "order_quantity", "") || "",
        order_received: _.get(editData, "order_received", "") || "",
        unit_price: _.get(editData, "unit_price", "") || "",
        total_price: _.get(editData, "total_price", "") || "",
        expiry_date: _.get(editData, "expiry_date", "") || "",
        batch_number: _.get(editData, "batch_number", "") || "",
      }
  let storageData = {
    ...storageCreate,
    company_id: _.get(editData, "company_id", "") || "",
    reorder_quantity: _.toString(_.get(editData, "reorder_quantity", "")) || "",
  }

  let directFormData = isStorage ? storageData : storeData

  const config = {
    ...commonData,
    ...directFormData,
  }

  return config
}

export const doValidationSchema = (
  props,
  isStorage = false,
  viewPage = "edit"
) => {
  const { isTax = false } = props

  let taxSchema = isTax
    ? {
      taxRate: Yup.string()
        .trim("White spaces cannot be allowed")
        .strict(true)
        .required("Enter Tax Rate")
        .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
      taxValue: Yup.string()
        .trim("White spaces cannot be allowed")
        .strict(true)
        .required("Enter Tax Value")
        .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
    }
    : {}

  let commonSchema = {
    productName: Yup.string().required("Select Item ID"),
    inventoryOnHand: Yup.string()
      .required("Enter Available on Hand")
      .trim("White spaces cannot be allowed")
      .strict(true)
      .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
    minimumThreshold: Yup.string()
      .trim("White spaces cannot be allowed")
      .strict(true)
      .required(isStorage ? "Enter Reorder Threshold" : "Enter Minimum Threshold")
      .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
  }

  let storeSchema = {
    ...taxSchema,
    listPrice: Yup.string()
      .trim("White spaces cannot be allowed")
      .strict(true)
      .required("Enter List Price")
      .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
    offerPrice: Yup.string()
      .trim("White spaces cannot be allowed")
      .strict(true)
      .required("Enter Offer Price")
      .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
  }

  let storageCreate =
    viewPage === "edit"
      ? {}
      : {
        package_type: Yup.string().required("Select Package Received"),
        package_received: Yup.string()
          .trim("White spaces cannot be allowed")
          .strict(true)
          .required("Enter Package Received")
          .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
        order_quantity: Yup.string()
          .trim("White spaces cannot be allowed")
          .strict(true)
          .required("Enter Order Quantity")
          .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
        order_received: Yup.string()
          .trim("White spaces cannot be allowed")
          .strict(true)
          .required("Enter Order Received")
          .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
        unit_price: Yup.string()
          .trim("White spaces cannot be allowed")
          .strict(true)
          .required("Enter Unit Price")
          .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`)
          .matches(
            /^\d+(\.\d{1,2})?$/,
            `Please enter a valid decimal value with up to two decimal places`
          ),
        total_price: Yup.string()
          .trim("White spaces cannot be allowed")
          .strict(true)
          .required("Enter Total Price")
          .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
        batch_number: Yup.string()
          .required("Enter Available on Hand")
          .trim("White spaces cannot be allowed")
          .strict(true),
      }

  let storageSchema = {
    ...storageCreate,
    company_id: Yup.string().required("Select Vendor"),
    reorder_quantity: Yup.string()
      .trim("White spaces cannot be allowed")
      .strict(true)
      .required("Enter Reorder Quantity")
      .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
  }

  let formSchema = isStorage ? storageSchema : storeSchema

  const config = {
    ...commonSchema,
    ...formSchema,
  }

  return config
}

export const doRenderList = (isStorage = false, viewPage = "") => {
  let store = [
    renderFieldInventoryOnHand,
    renderFieldMinimumThreshold,
    renderFieldListPrice,
    renderFieldOfferPrice,
    renderFieldTaxRate,
    renderFieldTaxValue,
  ]
  let storage =
    viewPage === "edit"
      ? [
        renderFieldReorderQuantity,
        renderFieldMinimumThreshold,
        renderFieldVendor,
      ]
      : [
        renderFieldInventoryOnHand,
        renderFieldReorderQuantity,
        renderFieldMinimumThreshold,
        renderFieldPackageType,
        renderFieldPackageReceived,
        renderFieldOrderQuantity,
        renderFieldOrderReceived,
        renderFieldUnitPrice,
        renderFieldTotalPrice,
        renderFieldExpiryDate,
        renderFieldBatchNumber,
        renderFieldVendor,
      ]
  let direct = isStorage ? storage : store
  return [...direct]
}
